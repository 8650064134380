import React, { Component } from "react"
import { QuantityChanger } from "@common"
import Variants from "./Variants"

const cleanPrice = price => {
  if (!price) return null
  let _p = price.split("&nbsp;")[0]

  if (_p.indexOf(",") !== -1) {
    _p = _p.replace(",", "")
  }

  return parseFloat(_p)
}

class AddToCartWithVariants extends Component {
  state = {
    value: null,
    regularValue: null,
    quantity: 1,
    product_id: null,
    variant_id: null,
    variant: null,
    sale: false,
    price: 0,
    regularPrice: 0,
    unit: "PLN",
    name: "",
    slug: "",
  }

  changeQty = val => {
    const { price, regularPrice } = this.state
    const _number = Number(val)
    if (_number <= 0 || Number.isNaN(_number)) {
      return false
    } else {
      this.setState({
        quantity: _number,
        value: _number * price,
        regularValue: _number * regularPrice,
      })
    }
  }

  changeVariant = ({ variationId, regularPrice, salePrice }) => {
    const { quantity } = this.state
    if (salePrice) {
      this.setState({
        variant_id: variationId,
        sale: true,
        price: cleanPrice(salePrice),
        value: cleanPrice(salePrice) * quantity,
        regularPrice: cleanPrice(regularPrice),
        regularValue: cleanPrice(regularPrice) * quantity,
      })
    } else {
      this.setState({
        variant_id: variationId,
        sale: false,
        price: cleanPrice(regularPrice),
        value: cleanPrice(regularPrice) * quantity,
        regularPrice: cleanPrice(regularPrice),
        regularValue: cleanPrice(regularPrice) * quantity,
      })
    }
  }

  componentDidMount() {}

  static getDerivedStateFromProps(props, state) {
    if (state.variant_id === null) {
      let updatedState = { ...state }
      const { variableProduct } = props
      let rev = variableProduct.variations.nodes
      const [first] = rev.reverse()
      const regularPrice = cleanPrice(first.regularPrice)

      if (first.salePrice) {
        updatedState = {
          ...updatedState,
          product_id: variableProduct.productId,
          variant_id: first.variationId,
          name: variableProduct.name,
          slug: variableProduct.slug,
          sale: true,
          price: cleanPrice(first.salePrice),
          value: cleanPrice(first.salePrice),
          regularPrice: cleanPrice(first.regularPrice),
          regularValue: cleanPrice(first.regularPrice),
        }
      } else {
        updatedState = {
          ...updatedState,
          product_id: variableProduct.productId,
          variant_id: first.variationId,
          name: variableProduct.name,
          slug: variableProduct.slug,
          sale: false,
          price: cleanPrice(first.regularPrice),
          regularPrice: cleanPrice(first.regularPrice),
          regularValue: cleanPrice(first.regularPrice),
          value: cleanPrice(first.regularPrice),
        }
      }
      return updatedState
    }
    return { ...state }
  }

  render() {
    const { quantity, value, regularValue, sale, variant_id } = this.state
    const { variableProduct } = this.props
    return (
      <div>
        {variableProduct.variations && (
          <div className="row justify-content-center">
            <Variants
              variations={variableProduct.variations}
              selected={this.state.variant_id}
              onVariantChange={this.changeVariant}
            />
          </div>
        )}
        <div className="row justify-content-center">
          <QuantityChanger onChange={this.changeQty} quantity={quantity} />
          <div className="product-price">
            {sale && <span className="strikethrough">{regularValue}PLN</span>}{" "}
            {value}PLN{" "}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-xl-6">
            <a
              className="product-btn"
              onClick={() => {
                this.props.onAddToCart(this.state)
              }}
            >
              Dodaj do koszyka
            </a>
          </div>
          <div className="col-12 col-md-12 col-xl-6">
            <a className="product-btn alt" href="/checkout/">
              Przejdź do płatności
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default AddToCartWithVariants
