import React, { useEffect } from "react"
import { Stepper, Modal, Reviews } from "@common"
import { WPApi } from "@api"
import { GallerySlider, CrossSellingSlider } from "./Sliders"
import Description from "./Description"
import Attributes from "./Attributes"
import AddToCart from "./AddToCart"

const SimpleProduct = props => {
  const { addToCart, simpleProduct, onGalleryClick, items } = props

  console.log(simpleProduct)

  let visitorEmail = null
  if (typeof window !== "undefined") {
    visitorEmail = localStorage.getItem("visitorEmail")
  }

  // if items will change send to cf
  // let itemsIds = []
  //   useEffect(() => {
  // 	  if(items){
  // 		  itemsIds = items.map(el => el.product_id)
  // 		  if(itemsIds.length > 0 && visitorEmail){
  // 			  // WPApi.catchCart(visitorEmail, itemsIds)
  // 			  console.log('itemsIds', itemsIds)
  // 		  }
  // 	  }
  //   }, [items])

  return (
    <React.Fragment>
      <div className="product">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 gallery-slider">
              <GallerySlider
                onClick={element => {
                  onGalleryClick(element)
                }}
                galleryImages={simpleProduct.galleryImages}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <h2 className="product-title">{simpleProduct.name}</h2>
              <Description description={simpleProduct.shortDescription} />
              {simpleProduct.attributes && (
                <Attributes attributes={simpleProduct.attributes.nodes} />
              )}
              <AddToCart
                {...simpleProduct}
                onAddToCart={item => {
                  addToCart({
                    ...item,
                    image: simpleProduct.image,
                  })
                }}
              />
              <hr />
            </div>
          </div>
          <Stepper>
            <div className="col-12" title={"Pełny opis"}>
              <Description description={simpleProduct.description} />
              <Reviews
                title={"Opinie"}
                productId={simpleProduct.productId}
                contextReviews={simpleProduct.reviews.nodes}
              />
            </div>
          </Stepper>
          <div className="row">
            {simpleProduct.crossSell.nodes.length > 0 && (
              <div className="col-sm-12 col-md-12">
                <div className="row justify-content-center">
                  <h1>Polecamy także</h1>
                </div>
                <div className="products-slider">
                  <CrossSellingSlider crossSell={simpleProduct.crossSell} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SimpleProduct
