import React, { useEffect } from "react"
import { WPApi } from "@api"
import { Stepper, Reviews } from "@common"
import Description from "./Description"
import Attributes from "./Attributes"
import AddToCartWithVariants from "./AddToCartWithVariants"
import { GallerySlider, CrossSellingSlider } from "./Sliders"

const VariableProduct = ({
  addToCart,
  variableProduct,
  onGalleryClick,
  items,
}) => {
  let visitorEmail = null
  if (typeof window !== "undefined") {
    visitorEmail = localStorage.getItem("visitorEmail")
  }
  // if items will change parse them to localStorage
  // let itemsIds = []
  //   useEffect(() => {
  // 	  if(items){
  // 		  itemsIds = items.map(el => el.product_id)
  // 		  if(itemsIds.length > 0 && visitorEmail){
  // 			  // WPApi.catchCart(visitorEmail, itemsIds)
  // 			  console.log('itemsIds', itemsIds)
  // 		  }
  // 	  }
  //   }, [items])

  return (
    <React.Fragment>
      <div className="product">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 gallery-slider">
              <GallerySlider
                galleryImages={variableProduct.galleryImages}
                onClick={element => {
                  onGalleryClick(element)
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <h2 className="product-title">{variableProduct.name}</h2>
              <Description description={variableProduct.shortDescription} />
              {variableProduct.attributes && (
                <div className="row">
                  <div className="col">
                    <Attributes attributes={variableProduct.attributes.nodes} />
                  </div>
                </div>
              )}
              <AddToCartWithVariants
                variableProduct={variableProduct}
                onAddToCart={item => {
                  addToCart({ ...item, image: variableProduct.image })
                }}
              />
            </div>
          </div>
          <Stepper>
            <div className="col-12" title={"Pełny opis"}>
              <Description description={variableProduct.description} />
              <Reviews
                title={"Opinie"}
                productId={variableProduct.productId}
                contextReviews={variableProduct.reviews.nodes}
              />
            </div>
          </Stepper>
          <div className="row">
            {variableProduct.crossSell.nodes.length > 0 && (
              <div className="col-sm-12 col-md-12">
                <div className="row justify-content-center">
                  <h1>Polecamy także</h1>
                </div>
                <div className="products-slider">
                  <CrossSellingSlider crossSell={variableProduct.crossSell} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default VariableProduct
