import React from "react"

const Description = ({ description }) => {
  return (
    <div
      className="product-description"
      dangerouslySetInnerHTML={{ __html: description }}
    ></div>
  )
}

export default Description
