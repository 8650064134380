import React from "react"

const Attributes = ({ attributes }) => {
  if (attributes) {
    return (
      <div className="product-attributes">
        {attributes.map(({ name, options }, k) => {
          return (
            <div className="product-attribute" key={k}>
              <h6 className="left-text">{name}</h6>
              <div className="center-line" />
              <h6 className="right-text">{options.join(" | ")}</h6>
            </div>
          )
        })}
      </div>
    )
  }
  return null
}

export default Attributes
