import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import {
  Layout,
  Head,
  Breadcrumbs,
  Mandala,
  Modal,
  ScalableImage,
} from "@common"
import { NewsletterSection } from "@sections"

import { SimpleProduct, VariableProduct } from "@pages/Product"

import { cartActions } from "../state"
import "./styles/product.scss"

const Product = props => {
  const modal = useRef(null)
  const [galleryElement, setGalleryElement] = useState({})
  const { pageContext, addToCart, resetCart, onChange, location, store } = props
  const { page } = pageContext
  const { nodes } = page.productCategories
  const { items } = store
  const ogImage = page.galleryImages.nodes[0].sourceUrl

  const image = {
    altText: "",
    sizes: "(max-width: 300px) 100vw, 300px",
    sourceUrl:
      "https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low.jpg",
    srcSet:
      "https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low-300x200.jpg 300w, https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low-1024x682.jpg 1024w, https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low-768x512.jpg 768w, https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low-1536x1024.jpg 1536w, https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low-1200x800.jpg 1200w, https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low-1980x1320.jpg 1980w, https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low-600x400.jpg 600w, https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/04/produkty-low.jpg 2000w",
  }

  let coupon = null
  if (location.search.includes("coupon") || location.search.includes("ref")) {
    coupon = location.search.split("=")[1]
  }
  if (coupon != null) {
    localStorage.setItem("urlCoupon", coupon)
  }

  if (page.type === "SIMPLE") {
    return (
      <Layout title={page.name} parallaxImg={image}>
        <Head
          seo={pageContext.page.seo}
          ogImage={ogImage}
          siteMetaData={pageContext.page.siteMetaData}
        />
        <Modal
          title={"Podgląd zdjęcia"}
          ref={modal}
          onClose={() => {
            setGalleryElement({})
          }}
        >
          <div className="row justify-content-center">
            {galleryElement.srcSet && (
              <ScalableImage srcSet={galleryElement.srcSet} />
            )}
          </div>
        </Modal>
        <div className="product">
          <div className="container">
            <Breadcrumbs
              elements={[
                { label: "Skarby", url: "/categories/" },
                { label: nodes[0].name, url: `/categories/${nodes[0].slug}` },
                {
                  label: page.name,
                  url: `/products/${page.slug}/`,
                },
              ]}
            />
            <SimpleProduct
              simpleProduct={page}
              addToCart={addToCart}
              resetCart={resetCart}
              items={items}
              onGalleryClick={element => {
                setGalleryElement(element)
                modal.current.toggle()
              }}
            />
          </div>
        </div>
        <Mandala height={250}>
          <div className="container">
            <div className="row justify-content-center">
              <Link className="button" to={`/categories/${nodes[0].slug}`}>
                Wróć do {nodes[0].name}
              </Link>
            </div>
          </div>
        </Mandala>
        <NewsletterSection />
      </Layout>
    )
  } else if (page.type === "VARIABLE") {
    return (
      <Layout title={page.name} parallaxImg={image}>
        <Head
          seo={pageContext.page.seo}
          siteMetaData={pageContext.page.siteMetaData}
          ogImage={ogImage}
        />
        <Modal
          title={"Podgląd zdjęcia"}
          ref={modal}
          onClose={() => {
            setGalleryElement({})
          }}
        >
          <div className="row justify-content-center">
            {galleryElement.srcSet && (
              <ScalableImage srcSet={galleryElement.srcSet} />
            )}
          </div>
        </Modal>
        <div className="product">
          <div className="container">
            <Breadcrumbs
              elements={[
                { label: "Skarby", url: "/categories/" },
                { label: nodes[0].name, url: `/categories/${nodes[0].slug}` },
                {
                  label: page.name,
                  url: `/products/${page.slug}/`,
                },
              ]}
            />
            <VariableProduct
              variableProduct={page}
              addToCart={addToCart}
              resetCart={resetCart}
              items={items}
              onGalleryClick={element => {
                setGalleryElement(element)
                modal.current.toggle()
              }}
            />
          </div>
        </div>
        <Mandala height={250}>
          <div className="container magictime swap">
            <div className="row justify-content-center">
              <Link className="button" to={`/categories/${nodes[0].slug}`}>
                Wróc do sklepu
              </Link>
            </div>
          </div>
        </Mandala>
        <NewsletterSection />
      </Layout>
    )
  } else {
    return (
      <Layout title={"NIE MOŻNA ZNALEŹĆ PRODUKTU"} parallaxImg={image}>
        <Head />
        <div className="container">
          <Breadcrumbs elements={[{ label: "Skarby", url: "/categories/" }]} />
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({ store: state.Cart })

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Product)
